import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Navbar } from "react-bootstrap"
import logo from '../../content/assets/logo.svg'

class Footer extends React.Component {
  render() {
  return (
      <footer
      className=""
      style={{
        marginTop: 70,
        backgroundColor: 'var(--bg)',
        color: 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
      }}
    >
      <Container fluid>
        <hr />
        <Row className="p-4">
          <Col md={8} className="mb-5">
            <h5>The Lake District Guide</h5>
            <p>Discover the English Lakes online.</p>
          </Col>
          <Col md={2} sm={6} xs={6}>
            <ul className="list-unstyled text-md-right text-sm-center text-xs-center ml-0">
              <li><h5>Sponsors</h5></li>
              <li><a href="https://wolfonmoon.com" target="_blank" rel="noopener noreferrer">Wolf on Moon</a></li>
              <li><a href="https://www.mrandmrshicks.com/" target="_blank" rel="noopener noreferrer">Mr & Mrs Hicks</a></li>
              <li><a href="https://www.holidaycottagescumbria.com/">Holiday Cottages Cumbria</a></li>
              <li><a href="https://cbaevents.co.uk/">CBA Events @ Grathwaite</a></li>
            </ul>
          </Col>
          <Col md={2} sm={6} xs={6}>
            <ul className="list-unstyled text-md-right text-sm-center text-xs-center ml-0">
              <li><h5>Other Resources</h5></li>
              <li><a href="https://www.lakedistrict.gov.uk/" target="_blank" rel="noopener noreferrer">Lake District National Park</a></li>
              <li><a href="https://en.wikipedia.org/wiki/Lake_District" target="_blank" rel="noopener noreferrer">Lake District on Wikipedia</a></li>
              <li><a href="https://www.golakes.co.uk/" target="_blank" rel="noopener noreferrer">Go Lakes</a></li>
              <li><a href="https://www.lonelyplanet.com/england/cumbria-and-the-lakes/lake-district" target="_blank" rel="noopener noreferrer">Lonely Planet</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <hr />
        <Row>
          <Col md={6} sm={12} xs={12} className="d-inline-block pb-3 text-md-left text-sm-center text-xs-center">
          <Navbar.Brand href="/">
          <img
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="The Lake District Guide"
          />
          {'  '}
          The Lake District Guide
        </Navbar.Brand>
            © {new Date().getFullYear()} 
          </Col>
          <Col md={6} sm={12} xs={12} className="d-inline-block pt-2 pb-3 text-md-right text-sm-center text-xs-center">
            Website by
            {'  '}
            <a href="https://wolfonmoon.com" target="_blank" rel="noopener noreferrer">
              Wolf on Moon
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
}

export default Footer