import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import styled from "styled-components"
import BigHero from "./bighero"

const BackgroundSection = ({ className, title, image, description }) => (
  <StaticQuery
    query={graphql`
      query {
        cycling: file(relativePath: { eq: "cycling.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        shooting: file(relativePath: { eq: "shooting.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        swimming: file(relativePath: { eq: "swimming.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        paddleboarding: file(relativePath: { eq: "paddleboarding.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        fellRunning: file(relativePath: { eq: "fell-running.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
      }
    `}
    render={data => {
      // Set ImageData.
      const imageName = image.toString()
      const imageData = data[imageName].childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          <BigHero title={title + " in The Lake District"} text={description} />
        </BackgroundImage>
      )
    }}
  />
)

const BigBanner = styled(BackgroundSection)`
  width: 100%;
  background-position: top center;
  background-repeat: repeat-y;
  background-size: cover;
  height: 100vh;
`

export default BigBanner
