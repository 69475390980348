import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "./navigation"
import Footer from "./footer"
// import HomeBanner from "./homepage/banner"
import Banner from "./banner"
import BigBanner from "./bigbanner"
import HomeCarousel from './homepage/carousel'

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === '/'){location.pathname = "/Home/"}
    let name = location.pathname.replace(/\\|\//g,'').replace('-', ' ')
    let shortName = name.replace('activities', '')
    let nameCapitalized = shortName.charAt(0).toUpperCase() + shortName.slice(1)

    if (shortName === "fell running") {
      shortName = "fellRunning"
    }
    console.log(location.pathname)
    // Display the homepage banner component if the path is '/'.
    if (location.pathname === rootPath || location.pathname === "/Home/") {
      header = (
        <div>
        {/**<HomeBanner />**/}
        <HomeCarousel />
        </div>
      )
    }
    // Display the normal banner component if the path is one of the main pages.
    else if (
      location.pathname === ('/about') || location.pathname === ('/about/') ||
      location.pathname === ('/lakes') || location.pathname === ('/lakes/') ||
      location.pathname === ('/fells') || location.pathname === ('/fells/') ||
      location.pathname === ('/blog') || location.pathname === ('/blog/') ||
      location.pathname === ('/list') || location.pathname === ('/list/') ||
      location.pathname === ('/wainwrights') || location.pathname === ('/wainwrights/')
      )
      {
      // The name variable is the pathname without any slashes.
      
      header = (
        <div>
        <Banner title={nameCapitalized} image={shortName} />
        </div>
      )
    }
    else if (
      location.pathname === ('/activities/swimming') || location.pathname === ('/activities/swimming/') ||
      location.pathname === ('/activities/cycling') || location.pathname === ('/activities/cycling/') ||
      location.pathname === ('/activities/shooting') || location.pathname === ('/activities/shooting/') ||
      location.pathname === ('/activities/fell-running') || location.pathname === ('/activities/fell-running/') ||
      location.pathname === ('/activities/paddleboarding') || location.pathname === ('/activities/paddleboarding/')
      )
      {
        header = (
        <div>
          <BigBanner title={nameCapitalized} image={shortName} />
        </div>
        )
      }
    else {
      // The header is an empty div if the user is not on ont of the main pages.
      header = (
        <div>
        </div>
      )
    }

    return (
      <div style={{
        backgroundColor: 'var(--bg)',
        color: 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
      }}>
      <Navigation {...this.props} />
      <header>
        {header}
      </header>
        <main id="main">{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
