import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import { Navbar, Nav, Dropdown } from "react-bootstrap"
import logo from '../../content/assets/logo.svg'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon,  } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'gatsby'

class Navigation extends React.Component {
  render() {
    const { location } = this.props
  // const metaDescription = description || site.siteMetadata.description

  return (
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
        <Navbar
          sticky="top"
          style={{
          backgroundColor: 'var(--bg)',
          color: 'var(--textNormal)',
          }}
          expand="xl"
          width="100%"
          height="100%"
          className={"mr-auto navbar-" + theme}
          >
          <Link to='/'>
          <Navbar.Brand>
          <img
            src={logo}
            width="60"
            height="34"
            className="d-inline-block align-top"
            alt="The Lake District Guide logo"
          />
          {'  '}
         The Lake District Guide
        </Navbar.Brand>
        </Link>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="">
            <Nav justify fill as="ul" style={{
              color: 'var(--textNormal)',
              }}>
              <Nav.Item as="li" className={
                location.pathname.includes('/lakes') ? 'active' : ''
              }>
                <Link to="/lakes/" eventkey="lakes">Lakes</Link>
              </Nav.Item>
              <Nav.Item as="li" className={
                location.pathname.includes('/fells') ? 'active' : ''
              }>
                <Link to="/fells/" eventkey="fells">Fells</Link>
              </Nav.Item>
              <Nav.Item as="li" className={
                location.pathname.includes('/blog') ? 'active' : ''
              }>
                <Link to="/blog/" eventkey="blog">Blog</Link>
              </Nav.Item>
              <Nav.Item as="li" className={
                location.pathname.includes('/wainwrights') ? 'active' : ''
              }>
                <Link to="/wainwrights/" eventkey="wainwrights">Wainwrights</Link>
              </Nav.Item>
              <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                Activities
              </Dropdown.Toggle>

              <Dropdown.Menu>
              <Nav.Item as="li" className={
                location.pathname.includes('/cycling') ? 'active' : ''
              }>
                <Link to="/activities/cycling/" eventkey="cycling">Cycling</Link>
              </Nav.Item>
              <Nav.Item as="li" className={
                location.pathname.includes('/paddleboarding') ? 'active' : ''
              }>
                <Link to="/activities/paddleboarding/" eventkey="paddleboarding">Paddleboarding</Link>
              </Nav.Item>
              <Nav.Item as="li" className={
                location.pathname.includes('/fell-running') ? 'active' : ''
              }>
                <Link to="/activities/fell-running/" eventkey="fell-running">Fell running</Link>
              </Nav.Item>
              <Nav.Item as="li" className={
                location.pathname.includes('/swimming') ? 'active' : ''
              }>
                <Link to="/activities/swimming/" eventkey="swimming">Swimming</Link>
              </Nav.Item>
              <Nav.Item as="li" className={
                location.pathname.includes('/shooting') ? 'active' : ''
              }>
                <Link to="/activities/shooting/" eventkey="shooting">Shooting</Link>
              </Nav.Item>
              </Dropdown.Menu>
            </Dropdown>
            </Nav>
            <hr className="hidden-lg-up" />
            <Nav
              justify
              fill
              as="ul"
              className="ml-auto nav-right"
              style={{
                color: 'var(--textNormal)',
                }}>
                <Nav.Item className='social-link' as="li">
                <a href="https://www.youtube.com/channel/UCuzM3blx3UYxTEj3mJR-sBg" target="_blank" alt="YouTube" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faYoutube} />{' '}
                  YouTube
                </a>
              </Nav.Item>
              <Nav.Item className='social-link' as="li">
                <a href="https://twitter.com/thelakesguide" target="_blank" alt="Twitter" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} />{' '}
                  Twitter
                </a>
              </Nav.Item>
              <Nav.Item className='social-link' as="li">
                <a href="https://instagram.com/thelakesguide" target="_blank" alt="Instagram" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />{' '}
                  Instagram
                </a>
              </Nav.Item>
              <Nav.Item as="li">
                <label>
                  <input
                    type="checkbox"
                    onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                    checked={theme === 'dark'}
                  />{' '}
                  {theme === 'dark' ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon icon={faSun} />}
                  {' '}
                {theme === 'dark' ? "Dark" : "Light"}
                </label>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        )}
      </ThemeToggler>
  )
  }
}

export default Navigation