import React from "react"
import { Jumbotron } from "react-bootstrap"
import { AiFillCaretDown } from "react-icons/ai"
class Hero extends React.Component {
  render() {


  return (
        <Jumbotron fluid className="jumbotron-background-image text-center">
              <div className="bg-transluscent pt-5">
                <h1 className="mt-5 pt-5">{this.props.title}</h1>
                <h5 className="font-weight-bold">
                  {this.props.text}
                </h5>
                <a href="#main"><AiFillCaretDown size={120} className=""/></a>
              </div>
      </Jumbotron>
  )
}
}

export default Hero