import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import BackgroundImage from 'gatsby-background-image'
import styled from "styled-components"
import { Carousel } from 'react-bootstrap'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
// import Hero from './hero'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        homeImage: file(relativePath: { eq: "lakedistrict.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        paddleImage: file(relativePath: { eq: "paddle.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          thirdImage: file(relativePath: { eq: "keswick.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          cycleImage: file(relativePath: { eq: "cycle.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
      }
    `}
    render={data => {
      // Set ImageData.
      const home = data.homeImage.childImageSharp.fluid
      const paddle = data.paddleImage.childImageSharp.fluid
      const third = data.thirdImage.childImageSharp.fluid
      const cycle = data.cycleImage.childImageSharp.fluid
      return (
        <Carousel className="mb-5" fade={true}>
            <Carousel.Item interval={4000}>
                <Img
                className="d-block w-100"
                fluid={home}
                alt="The Lake District Guide"
                />
                <Carousel.Caption>
                <h2>The Lake District Guide</h2>
                <h4>Discover the English lakes online</h4>
                <p className="mt-5">
                <Link className="btn btn-lg  btn-primary" to="/lakes/" role="button">Lakes</Link>
                {'  '}
                <Link className="btn btn-lg  btn-secondary" to="/blog/" role="button">Blog</Link>
                </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
                <Img
                className="d-block w-100"
                fluid={third}
                alt="Read our blog"
                />

                <Carousel.Caption>
                <h2>Read our blog</h2>
                <h4>Read the latest posts from The Lake District</h4>
                <p className="mt-5">
                <Link className="btn btn-lg btn-primary" to="/blog/" role="button">Read our blog</Link>
                </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
                <Img
                className="d-block w-100"
                fluid={paddle}
                alt="Go Paddleboarding"
                />

                <Carousel.Caption>
                <h2>Go Paddleboarding</h2>
                <h4>Find out how to go paddleboarding in The Lake District</h4>
                <p className="mt-5">
                <Link className="btn btn-lg btn-primary" to="/activities/paddleboarding/" role="button">Find out more</Link>
                </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
                <Img
                className="d-block w-100"
                fluid={cycle}
                alt="Go Cycling"
                />

                <Carousel.Caption>
                <h2>Go Cycling</h2>
                <h4>Find out how to go cycling in The Lake District</h4>
                <p className="mt-5">
                <Link className="btn btn-lg btn-primary" to="/activities/cycling/" role="button">Find out more</Link>
                </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
      )
    }}
  />
)

const HomeCarousel = styled(BackgroundSection)`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`

export default HomeCarousel


