import React from "react"
import { Jumbotron } from "react-bootstrap"
import { AiFillCaretDown } from "react-icons/ai"

class BigHero extends React.Component {
  render() {


  return (
        <Jumbotron fluid className="bighero bg-transluscent-big jumbotron-background-image text-center">
                <h1 className="mb-5 p-5 ">{this.props.title}</h1>
                <p>
                  {this.props.text}
                  <a href="#main"><AiFillCaretDown size={120} className=""/></a>
                </p>
      </Jumbotron>
  )
}
}

export default BigHero